import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Component } from "react"

import { faTwitter, faInstagram, faYoutube, faTiktok, faPatreon } from "@fortawesome/free-brands-svg-icons";

import "./socialLinks_mob.css"


export default class SocialLinks extends Component {

    render(){
        return (
        <span id="social-links">
            <a href="https://www.twitter.com/Lou_Raccoon" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={ faTwitter } /></a>
            <a href="https://www.instagram.com/lou_raccoon/" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={ faInstagram } /></a>
            <a href="https://www.youtube.com/channel/UC3T348_do4wUf56TSGjwNwA" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={ faYoutube }  /></a>
            <a href="https://www.tiktok.com/@lou_raccoon" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={ faTiktok } /></a>
            <a href="https://www.patreon.com/lou_raccoon" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={ faPatreon } /></a>
        </span>)
    }
}