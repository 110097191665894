import { Component } from "react";
import Col from "react-bootstrap/Col"

export default class PriceOffer extends Component
{

    render(){
        return(
        <Col xs={this.props.xs} sm={this.props.sm}>
            <h3>{this.props.ProductName} - £{this.props.ProductPrice} (GBP)</h3>
            <h4 style={{ display:this.props.Mult?"block":"none" }}><i>[ Equivalent approximately ${Math.floor(this.props.ProductPrice * this.props.Mult)} (USD) ]</i></h4>
            <p>
                {this.props.children}
            </p>
        </Col>
        )
    }
}