import { Component } from 'react';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import SocialLinks from '../components/socialLinks';

import logo from '../images/gallery/image_7.avif';
import './p_contact_mob.css';
export default class Contact extends Component {

  constructor(props) {
    super(props);

    this.state = {
      message: "",
      lname: "",
      fname: "",
      email: "",
      error: null,
      showToast: false,
      enableSend: true
    }

    this.submitForm = this.submitForm.bind(this);
    this.handleFnameChange = this.handleFnameChange.bind(this);
    this.handleLnameChange = this.handleLnameChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleToastDismiss = this.handleToastDismiss.bind(this);
  }

  handleEmailChange(e){
    let filteredValue = e.target.value.substr(0,64); //Double filter.
    this.setState({ email: filteredValue });
  }
  handleFnameChange(e){
    let filteredValue = e.target.value.substr(0,24); //Double filter.
    this.setState({ fname: filteredValue });
  }
  handleLnameChange(e){
    let filteredValue = e.target.value.substr(0,24); //Double filter.
    this.setState({ lname: filteredValue });
  }
  handleMessageChange(e){
    let filteredValue = e.target.value.substr(0,1024); //Double filter.
    this.setState({ message: filteredValue });
  }

  handleToastDismiss(e){
    this.setState({ error: null, showToast: false });
  }

  async submitForm(e) {
    e.preventDefault(); //Stops page refresh.

    this.setState({enableSend:false});

    await fetch(process.env.REACT_APP_API_URL, {
      method:"POST",
      body: JSON.stringify({
        email:this.state.email,
        fname:this.state.fname,
        lname:this.state.lname,
        message:this.state.message,
      }),
      headers: {
        "Content-type":"application/json; charset=UTF-8"
      }
    })
    .then(response=>{
      switch (response.status)
      {
        case 200: this.setState({ email:"", fname:"", lname:"", message:"", error:null, showToast:true }); break;
        default:  this.setState({ error: response.statusText, showToast:true  });
      }
    })
    .catch(error=>{
      this.setState({error:"Couldn't contact server, please email the owner directly to inform them.", showToast: true })
    })
    .finally(()=>{
      this.setState({ enableSend:true })
    });

  }

  render() {
    return (
      <Container>
        <div id="banner" style={{ backgroundImage: `url(${logo})` }}>
          <div id="banner-overlay">
            <h1>Contact Me</h1>
            <p>
              For all topics commission or work related, please contact me via
              the form or my email below. For social or informal communication,
              I am regularly active on my Twitter page and will be happy to
              answer any questions.
            </p>
          </div>
        </div>
        <Container id="contact-page">
          <Row>
            <Col as="h2">Let's Chat!</Col>
          </Row>
          <Row id="socials">
            <Col sm={12} md={5} lg={4}>
              <label>Email </label>
              <br></br>
              <a href="mailto:kayleigh.louise.george@gmail.com">
                <span style={{ fontWeight: 'bold' }}>
                  kayleigh.louise.george@gmail.com
                </span>
              </a>
            </Col>
            <Col>
              <label>Social Media</label>
              <br></br>
              <SocialLinks />
            </Col>
          </Row>
          <Form id="contact-form" onSubmit={this.submitForm}>
            <Row>
              <Col sm={8} md={4} lg={3}>
                <FormGroup controlId="contactForm.FirstName">
                  <Form.Label column>First Name</Form.Label>
                  <Form.Control type="text" max="24" name="fname" value={this.state.fname} onChange={this.handleFnameChange} />
                </FormGroup>
              </Col>
              <Col sm={8} md={4} lg={3}>
                <FormGroup controlId="contactForm.LastName">
                  <Form.Label column>Last Name</Form.Label>
                  <Form.Control type="text" max="24" name="lname" value={this.state.lname} onChange={this.handleLnameChange}/>
                </FormGroup>
              </Col>
              <Col sm={8} md={4} lg={3}>
                <FormGroup controlId="contactForm.Email">
                  <Form.Label column>Email</Form.Label>
                  <Form.Control type="email" max="64" required name="email" placeholder="name@example.com" value={this.state.email} onChange={this.handleEmailChange}/>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                  <Form.Group controlId="contactForm.Message">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" required minLength={32} maxLength={1024} name="message" rows={5} value={this.state.message} onChange={this.handleMessageChange} />
                  </Form.Group>
              </Col>
            </Row>
            <Row>
              <div id="submit-row">
                <Button type="submit" disabled={!this.state.enableSend}>
                  Send
                </Button>
              </div>
            </Row>
          </Form>
        </Container>
        <Container fluid>

          <Col id="overlay" style={{display: this.state.showToast?"block":"none"}} onClick={this.handleToastDismiss}>
            <h1>{this.state.error?"Error":"Success"}</h1>
            <p>{this.state.error??"Message sent. Thank you for your interest!"}</p>
          </Col>
        </Container>
      </Container>
    );
  }
}
