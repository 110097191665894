import { Component } from "react";
import ReviewBox from "./customerReview";
import "./reviewBanner.css";
import Row from "react-bootstrap/Row";
export default class ReviewBanner extends Component
{

    constructor(props)
    {
        super(props)

        this.state = {
            index: 0,
            maxChildren:1,
        }

        this.nextReview = this.nextReview.bind(this);
    }


    nextReview()
    {
        this.setState({ index: (this.state.index+1)%this.state.maxChildren });
    }

    componentDidMount(){
        setInterval(this.nextReview, 7000);
        this.setState({ maxChildren: document.getElementById(this.props.id).children.length });
    }

    render(){
        return(
        <Row className="review-banner" id={this.props.id}>
            <ReviewBox display={this.state.index===0} author="@CoyoteInTheHat (Twitter)" date="April 2022" href="https://twitter.com/CoyoteInTheHat/status/1511340809941045248">
                I highly recommend Lou! They listened and sent me regular updates. Working with them was delightful! Every expectation was exceeded! Received a higher detailed piece than anticipated in a timely manner. Lou knew exactly what I wanted despite receiving a short pitch
            </ReviewBox>
            <ReviewBox display={this.state.index===1} author="@somedogyouare (Twitter)" date="May 2022" href="https://twitter.com/somedogyouare/status/1526939406669684737">
            🎨 @Lou_Raccoon is absolutely wonderful to work with/commission - go check em out!
            </ReviewBox>
            <ReviewBox display={this.state.index===2} author="@Pudgical (Twitter)" date="June 2022" href="https://twitter.com/Pudgical/status/1540383418764398592">
            I love love LOVE each and every expression, they're all so unique and how you do the movement in an otherwise still picture? Heck?? What is this sorcery?! Thank you so much! 🧡🧡
            </ReviewBox>
            <ReviewBox display={this.state.index===3} author="@Regdeh (Twitter)" date="July 2022" href="https://twitter.com/Regdeh/status/1552040924292812800">
            This was absolutely OUTSTANDING stuff lou gosh thank you so so incredibly much &lt;333 ^////^
            </ReviewBox>

        </Row>);
    }
}