import { Component } from "react";
import Col from "react-bootstrap/Col";

export default class ReviewBox extends Component
{
    constructor(props)
    {
        super(props)
        
        this.state = {
            href: props.href || "#",
            author:props.author,
            date: props.date,
            display: props.display || false,
        }
    }

    render()
    {
        return(
            <Col className={(this.props.display?"":"hidden")} >
                <a href={this.props.href} target="_blank" rel="noreferrer">
                    <q>{this.props.children}</q><br></br>
                - <i>{this.props.author} {this.props.date}</i>
                </a>
            </Col>
        )
    }
}