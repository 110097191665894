import { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ReviewBanner from '../components/reviewBanner';

import Logo from '../images/louraccoon.webp';
import './p_nHome_mob.css';
import './p_nHome.css';
import Container from 'react-bootstrap/Container';
import SocialLinks from '../components/socialLinks';

export default class Home extends Component {
  render() {
    return (
      <Container id="home-page" fluid>
        <ReviewBanner id="comments" />

        <Row>
          <Col id="logo-box" sm="6">
            <Image id="logo" src={Logo} />
          </Col>

          <Col id="button-box" sm="6">
            <Row id="button-box-text">
              <h1>My name is Lou.</h1>
              <h3>
                UK Based Illustrator with a BA Honours Degree in Animation
              </h3>
            </Row>

            <Row as="ul" id="button-list">
            <Button
                target="_blank"
                rel="noopener"
                href="https://www.patreon.com/lou_raccoon"
              >
                Patreon
              </Button>
              <Button
                target="_blank"
                rel="noopener"
                href="https://www.etsy.com/au/shop/GuacaMochni"
              >
                ETSY
              </Button>
              <Button href="/price-sheet">Commision Price Sheet</Button>
              <Button
                target="_blank"
                rel="noopener"
                href="https://trello.com/b/YZZdhD7B/lou-raccoon-commissions"
              >
                Commision Queue (Trello)
              </Button>
              <Button href="/contact">Contact Me</Button>
            </Row>

            <Row id="social-icons"><SocialLinks /></Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
