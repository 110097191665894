import { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import PriceOffer from '../components/priceOffer';
import './p_pricesheet_mob.css';
import './p_pricesheet.css';
import icon_example_a from '../images/gallery/compressed/icon_example_a.avif';
import icon_example_b from '../images/gallery/compressed/icon_example_b.avif';
import flat_colour_example from '../images/gallery/compressed/flat_example.avif';
import shaded_example from '../images/gallery/compressed/shaded_example.avif';
// import dating_example from '../images/gallery/compressed/image_21_compressed.avif'; picky deploy now wants perfect lint
import telegram_sheet_example from "../images/gallery/compressed/telegram_example.avif";
import ref_sheet from '../images/gallery/compressed/ref_example.avif';
import single_scene_example_a from '../images/single_scene_example_a.webp';
import single_scene_example_b from '../images/gallery/compressed/single_example.avif';
import toony_page_example from '../images/gallery/compressed/image_22_compressed.avif';
import full_scene_example_a from '../images/gallery/compressed/image_1_compressed.avif';
import full_scene_example_b from '../images/gallery/compressed/image_38_compressed.avif';
import full_scene_example_c from '../images/gallery/compressed/image_39_compressed.avif';

const PRICE_CHAR_ICON = 50;
const PRICE_CHAR_ICON_DEAL = 85;
const PRICE_FULL_F = 40;
const PRICE_FULL_F_ADDITIONAL = 30;
const PRICE_FULL_S = 60;
const PRICE_FULL_S_ADDITIONAL = 50;
const PRICE_TELEGRAM_STICKER = 20;
const PRICE_TELEGRAM_STICKERS_5 = 70;
const PRICE_TELEGRAM_STICKERS_10 = 130;
const PRICE_REF_SHEET = 65;
const PRICE_REF_SHEET_CU_ACC = 10;
const PRICE_REF_SHEET_EXPR = 20;
const PRICE_REF_SHEET_SIDE_OUTFIT = 30;
const PRICE_SINGLE_ILLUSTRATION = 75;
const PRICE_TOONY_PAGE = 75;
const PRICE_ILLUSTRATED_SCENE = 120;

export default class PriceSheet extends Component {

  constructor(props)
  {
    super(props);

    this.state = {
      usd_per_gbp: null
    }

    this.getUSDtoGBP = this.getUSDtoGBP.bind(this);
  }

  async getUSDtoGBP(){

    await fetch("https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=gbp%2Cusd")
    .then(async (response) => await response.json())
    .then((result) => {
      let { gbp, usd } = result.bitcoin;

      let usd_per_gbp = 105.00 * usd / gbp ; // Get 100 * USD/GBP +5%. 100* because we need 2 d.p. later.
      usd_per_gbp = Math.floor(usd_per_gbp); // Round down to nearest integer.
      usd_per_gbp = (usd_per_gbp / 100.00).toFixed(2); // Divide by 100 and truncate any excess after 2 d.p.

      this.setState({ usd_per_gbp });

      // console.log(result)
      // console.log(usd_per_gbp)
    })
    .catch(error=>{
      this.setState({ usd_per_gbp: null });
    });
  }

  async componentDidMount()
  {
    await this.getUSDtoGBP();
  }

  render() {
    return (
      <Container>
        <Row>
          <h2>Price Sheet</h2>
          <p>
            Interested in commissioning me? Please fill out my contact form,
            email me, or reach out to me on social media. Below are some base
            prices for the type of independent illustrations I can offer. Please
            note that commissions such as the ones demonstrated are only
            available for personal use. If you require artwork for commercial
            purposes, please contact me to discuss.
          </p>
        </Row>
        <Row className="center-text">
          <span>
            Prices are listed in GBP (£) and are payable through PayPal.
          </span>
          <p>
            <i>
              <strong>
                I have included approximate USD conversion prices but these are
                subject to change according to current conversion rate.<br></br>
                Please be sure to check conversion rates before querying a
                commission - my rates are not negotiable.
              </strong>
            </i>
          </p>
          <small>
            <i>by commissioning me you are agreeing to be over 18 years old.</i>
          </small>
        </Row>
        <Row className="price-row" id="icons">
          <Col sm="5">
            <Col className="double-image">
              <Image src={icon_example_a} alt="" style={{zIndex:1}} />
              <Image src={icon_example_b} alt="" />
            </Col>
          </Col>
          <PriceOffer ProductName="Character Icons" ProductPrice={PRICE_CHAR_ICON} Mult={this.state.usd_per_gbp}>
            Perfect for social media pages. Personalised with a character,
            expression and theme of your choice. Make it a pair for couples
            icons and save £{PRICE_CHAR_ICON_DEAL - PRICE_CHAR_ICON} <strong>(Couples icons £{PRICE_CHAR_ICON_DEAL} for a pair)</strong>.
          </PriceOffer>
        </Row>
        <Row className="price-row" id="full-body-flat">
          <Col sm="5" md={{ order: 1 }}>
            <Image src={flat_colour_example} alt="" />
          </Col>
          <PriceOffer
            ProductName="Full Body, Flat Illustration"
            ProductPrice={PRICE_FULL_F}
            Mult={this.state.usd_per_gbp}
          >
            Personalised with a character, expression and theme of your choice.
            Additional characters +£{PRICE_FULL_F_ADDITIONAL} each. More complex background +£10.
          </PriceOffer>
        </Row>
        <Row className="price-row" id="full-body-shaded">
          <Col sm="5">
            <Image src={shaded_example} alt="" />
          </Col>
          <PriceOffer
            ProductName="Full Body, Shaded Illustration"
            ProductPrice={PRICE_FULL_S}
            Mult={this.state.usd_per_gbp}
          >
            Personalised with a character, expression and theme of your choice.
            Additional characters +£{PRICE_FULL_S_ADDITIONAL} each. More complex backgroud +£20.
          </PriceOffer>
        </Row>
        {/* <Row className="price-row" id="dating-sim-uwu">
          <Col sm="5" md={{ order: 1 }}>
            <Image src={dating_example} alt="" />
          </Col>
          <PriceOffer ProductName="Dating Simulator" ProductPrice="40" Mult={this.state.usd_per_gbp}>
            Personalised with a character, expression and theme of your choice.
            Includes basic shading.
          </PriceOffer>
        </Row> */}
         <Row className="price-row" id="telegram-stickers">
          <Col sm="5" md={{ order: 1 }}>
            <Image src={telegram_sheet_example} alt="" />
          </Col>
          <PriceOffer ProductName="Telegram Sticker" ProductPrice={PRICE_TELEGRAM_STICKER} Mult={this.state.usd_per_gbp}>
            Save when you purchase in a set!<br></br>
            £{PRICE_TELEGRAM_STICKERS_5} for 5<br></br>
            £{PRICE_TELEGRAM_STICKERS_10} for 10
          </PriceOffer>
        </Row>
        <Row className="price-row" id="ref-sheet">
          <Col sm="5">
            <Image src={ref_sheet} alt="" />
          </Col>
          <PriceOffer ProductName="Base Ref Sheet" ProductPrice={PRICE_REF_SHEET} Mult={this.state.usd_per_gbp}>
          Base Ref Sheet (includes front and back)<br></br>
          Close ups & accessories +£{PRICE_REF_SHEET_CU_ACC} each.<br></br>
          Headshots & expressions +£{PRICE_REF_SHEET_EXPR} each.<br></br>
          Side views & outfits +£{PRICE_REF_SHEET_SIDE_OUTFIT} each.
          </PriceOffer>
        </Row>
        <Row className="price-row" id="single-character-scene">
          <Col sm="5" md={{ order: 1 }}>
            <Col className="double-image">
              <Image src={single_scene_example_a} alt="" />
              <Image src={single_scene_example_b} alt="" />
            </Col>
          </Col>
          <PriceOffer
            ProductName="Single Character Illustrated Scene"
            ProductPrice={PRICE_SINGLE_ILLUSTRATION}
            Mult={this.state.usd_per_gbp}
          >
            Personalised with a character, expression, pose and theme of your
            choice. Background included in cost.
          </PriceOffer>
        </Row>
        <Row id="toony-page" className="price-row">
          <Col sm="5">
            <Image src={toony_page_example} alt="" />
          </Col>
          <PriceOffer ProductName="Toony Page" ProductPrice={PRICE_TOONY_PAGE} Mult={this.state.usd_per_gbp}>
            Illustration depicting two characters interacting. Colour drop
            background. Shaded. Can be themed depending on client preference
            (hypnotism theme shown in example).
          </PriceOffer>
        </Row>
        <Row className="price-row" id="full-scene">
          <PriceOffer
            xs={{ order: 2, span: 12 }}
            sm={{ order: 0, span: 6 }}
            ProductName="Illustrated Scene"
            ProductPrice={PRICE_ILLUSTRATED_SCENE}
            Mult={this.state.usd_per_gbp}
          >
            Fully illustrated scene, background and characters. Fully rendered
            with shading. Subject to price increase depending on background
            complexity. Two characters included in price, additional characters
            +£40 each following this.(Another example below).
          </PriceOffer>

          <Col xs={{ span: 12, order: 1 }} sm="6">
            <Image src={full_scene_example_a} alt="" />
          </Col>
          <Col xs={{ span: 12, order: 3 }} sm="6">
            <Image src={full_scene_example_b} alt="" />
          </Col>
          <Col xs={{ span: 12, order: 4 }} sm="6">
            <Image src={full_scene_example_c} alt="" />
          </Col>
        </Row>

        <Row>
          <p>
            If you have an idea for a commission, but the type of commission is
            not listed, please do not hesitate to reach out to me using the on
            site contact form, email or social media pages.
          </p>
        </Row>
      </Container>
    );
  }
}
