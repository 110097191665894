import 'bootstrap/dist/css/bootstrap.min.css';
import { Component } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import PublicLayout from './layouts/publicLayout';
import Contact from './pages/p_contact';
import Home from './pages/p_nHome';
import PriceSheet from './pages/p_pricesheet';
import Gallery from './pages/p_gallery';

export default class App extends Component {
  
  render()
  {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <PublicLayout/> }>
            <Route path="gallery" element={ <Gallery/> } />
            <Route path="price-sheet" element={ <PriceSheet/> } />
            <Route path="contact" element={ <Contact/> } />
            <Route index element={ <Home/> } />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }

}
