import { Component } from 'react';
import Container from 'react-bootstrap/Container';
import { Outlet } from 'react-router-dom';
import Navigation from '../components/navbar';
import './publicLayout_mobile.css';
import './publicLayout.css';


export default class PublicLayout extends Component {
  render() {
    return (
      <Container fluid>
        <Navigation />

        <Outlet />

        <footer>&copy; 2022 Furhosting.co.uk</footer>
      </Container>
    );
  }
}
