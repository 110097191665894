import { Component } from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import "./p_gallery_mob.css";
import "./p_gallery.css";


function ImportAll(r){
  let images = [];
  r.keys().map((item,index) => {
    var extension = item.split('.').pop();
    if (extension === "avif" || extension === "gif")
      return images.push(r(item));
    else
    return "";
  });
  return images;
}

const ImageGalleryCompressed = ImportAll(require.context('../images/gallery/compressed',false));

export default class Gallery extends Component {

  constructor(props){
    super(props)

    this.state = {
      fullScreen: false,
      selectedImage: null,
    }

    this.viewFullScreen = this.viewFullScreen.bind(this);
    this.createGallery = this.createGallery.bind(this);
  }

  viewFullScreen(imageName = null)
  {
    this.setState({ fullScreen:!this.state.fullScreen, selectedImage: imageName?`/images/${imageName}`:"" })
  }
  
  createGallery(
    lowerQuartile = ImageGalleryCompressed.length/4, 
    middleQuartile = ImageGalleryCompressed.length/2,
    upperQuartile = ImageGalleryCompressed.length*3/4,
    ){

    let galleryInner = [1,2,3,4].map((index)=>{
      //Get correct items.
      let tempArray = [];
      switch (index) 
      {
        case 1: tempArray = ImageGalleryCompressed.slice(0,lowerQuartile); break;
        case 2: tempArray = ImageGalleryCompressed.slice(lowerQuartile,middleQuartile); break;
        case 3: tempArray = ImageGalleryCompressed.slice(middleQuartile,upperQuartile);break;
        case 4: tempArray = ImageGalleryCompressed.slice(upperQuartile, ImageGalleryCompressed.length);break;
        default:break;
      }

      //Return image column
      return (<Col sm={6} lg={3} key={index}>
        {
          tempArray.map((imageSrc, index) => { 
              var fileName = imageSrc.split('/').pop();

              //remove "_compressed" in file name if present.
              var imageName = fileName.replace("_compressed","");
              //split by period
              var splitByPeriod =  imageName.split('.');
              //remove added id info (join before ID and after ID sections)
              imageName = splitByPeriod[0] + '.' + splitByPeriod.pop();

              console.log(`Going from: %s,\nto: %s`,fileName,imageName);

              return (<Image src={imageSrc} width={350} key={index} onClick={()=>this.viewFullScreen(imageName)} />);
          })
        }
      </Col>)
    });

    return galleryInner;
  }


  render() {
    return (
      <Container id="gallery-top">
        <Row>
          <h1>Gallery</h1>
          <p>
            My portfolio of illustration. This ranges from commission work with
            clients to personal artwork.
<br></br>
            <small>Select an image to view it in high-res.</small>
          </p>
        </Row>
        <Row className="justify-content-xs-center" id="gallery">

          {
            this.createGallery(ImageGalleryCompressed.length/4 +1, ImageGalleryCompressed.length/2 +1)
          }
        </Row>
        <Button href="#gallery-top" id="quick-up">&#8593;</Button>

        <Container id="overlay" style={{ display: this.state.fullScreen?"block":"none" }} onClick={()=>this.viewFullScreen(null)} >
            <Image src={this.state.selectedImage} />
        </Container>
      </Container>
    );
  }
}
