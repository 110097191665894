import { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import NavLink from 'react-bootstrap/NavLink';
import './navbar.css';

export default class Navigation extends Component {
  render() {
    return (
      <Navbar collapseOnSelect expand="md" variant="dark">
        <Navbar.Text>
            Lou Raccoon | Digital Illustrator
        </Navbar.Text>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <NavLink href="/">Home</NavLink>
          <NavLink href="/gallery">Gallery</NavLink>
          <NavLink href="/price-sheet">Price Sheet</NavLink>
          <NavLink
            href="https://trello.com/b/YZZdhD7B/lou-raccoon-commissions"
            target="_blank"
          >
            Queue
          </NavLink>
          <NavLink
            href="https://www.etsy.com/au/shop/GuacaMochni"
            target="_blank"
          >
            Shop
          </NavLink>
          <NavLink href="/contact">Contact</NavLink>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
